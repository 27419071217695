
import { computed, defineComponent } from "vue";
import useThemeSettings from "@/hooks/themeSettings";
import { useCompanyStore } from "@/stores/CompanyStore";
import { useRoute, useRouter } from "vue-router";

const __default__ = defineComponent({
  name: "HeaderComponent",
  props: ["category"],
  components: {},
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const getCategoryName = computed(() => props.category);
    const handleLogoClick = function () {
      if (companyInfo.website) {
        window.location.href = companyInfo.website;
      }
      // window.location.href =
      //   "https://cheetateamwear.com.au/pages/design-your-own";

      // if (route.name === "home") {
      //   window.open("https://cheetateamwear.com.au/pages/design-your-own");
      // } else {
      //   router.push({ name: "home" });
      // }
    };

    return {
      baseUrl,
      accentColor,
      accentColorHover,
      getCategoryName,
      companyInfo,
      handleLogoClick,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5ad8b0dc": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__