import { defineStore } from "pinia";
import { ref, computed } from "vue";
import type { Ref } from "vue";
import { useStorage } from "@vueuse/core";
import { useFavicon } from "@vueuse/core";

import { API } from "@/api";

export const useCompanyStore = defineStore("CompanyStore", () => {
  const api = new API();

  const companyInfo: Ref<{
    id: number;
    name: string;
    accentColor: string | null;
    accentColorHover: string | null;
    logo: string | null;
    favicon: string | null;
    mainPageH1: string | null;

    email: string | null;
    phone: string | null;
    address: string | null;
    website: string | null;
    socialUrls: [] | null;
    termsUrl: string | null;
    privacyUrl: string | null;
  } | null> = ref(null);

  const getCompanyInfo = computed(() => {
    return companyInfo.value;
  });
  function setCompanyInfo(info: {
    id: number;
    name: string;
    accentColor: string | null;
    accentColorHover: string | null;
    logo: string | null;
    favicon: string | null;
    mainPageH1: string | null;

    email: string | null;
    phone: string | null;
    address: string | null;
    website: string | null;
    socialUrls: [] | null;
    termsUrl: string | null;
    privacyUrl: string | null;
  }) {
    companyInfo.value = info;

    const icon = useFavicon();
    icon.value = companyInfo.value.favicon
      ? companyInfo.value.favicon
      : "favicon.ico";
  }

  const retrieveCompanyInfo = async (domain: string) => {
    // setCompanyInfo({
    //   accentColor: "#b36eec",
    //   accentColorHover: "#a041ee",
    //   id: 1,
    //   logo: "http://localhost/storage/company/logo/5/logo.png",
    //   name: "SecondCo",
    // });
    const response = await api.getCompanyInfo(domain);

    if (response.data.data) {
      setCompanyInfo(response.data.data);
    }
  };

  return {
    companyInfo,
    getCompanyInfo,
    setCompanyInfo,
    retrieveCompanyInfo,
  };
});
